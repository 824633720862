import React from 'react';

const WarnBorder = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="70"
    height="70"
    viewBox="0 0 70 70"
  >
    <defs>
      <clipPath>
        <rect width="45.309" height="40.552" fill="currentColor" />
      </clipPath>
    </defs>
    <g transform="translate(-183 -1697)">
      <g transform="translate(-628 1426)">
        <g transform="translate(811 271)" fill="none" stroke="currentColor" strokeWidth="1">
          <rect width="70" height="70" stroke="none" />
          <rect x="0.5" y="0.5" width="69" height="69" fill="none" />
        </g>
      </g>
      <g transform="translate(195.346 1711.724)">
        <g clipPath="url(#clip-path)">
          <path
            d="M22.673,40.55q-8.559,0-17.118,0a5.689,5.689,0,0,1-3.34-.958,5.1,5.1,0,0,1-1.7-6.4c1.114-2.124,2.358-4.18,3.557-6.259Q11.02,14.9,17.971,2.87A5.185,5.185,0,0,1,22,.042,4.835,4.835,0,0,1,26.945,2.3c1.518,2.418,2.9,4.924,4.327,7.4q6.591,11.4,13.179,22.794a5.232,5.232,0,0,1-4.661,8.059q-8.559,0-17.118,0M22.634,39.1H39.619c.265,0,.531,0,.8-.024A3.536,3.536,0,0,0,42.857,37.9a3.746,3.746,0,0,0,.369-4.656Q36.053,20.828,28.877,8.419C27.919,6.761,26.969,5.1,26,3.447a3.737,3.737,0,0,0-3.965-1.965,3.831,3.831,0,0,0-2.806,2.074q-7.3,12.667-14.625,25.325c-.928,1.607-1.883,3.2-2.756,4.835a3.451,3.451,0,0,0,.229,3.692,3.785,3.785,0,0,0,3.437,1.7c5.706-.013,11.411-.005,17.117-.005"
            transform="translate(0 0.001)"
            fill="currentColor"
          />
          <path
            d="M115.659,58.194a3.15,3.15,0,0,1,3.1,3.486q-.44,5.7-.862,11.407a2.294,2.294,0,0,1-2.02,2.26,2.186,2.186,0,0,1-2.341-1.552,9,9,0,0,1-.208-1.659q-.406-5.263-.793-10.526a3.128,3.128,0,0,1,3.125-3.416m-1.728,3.618.06.009q.411,5.483.819,10.966c.056.757.329,1.142.841,1.138s.778-.389.831-1.148c.1-1.424.213-2.848.32-4.272.177-2.349.36-4.7.525-7.046a1.693,1.693,0,0,0-1.313-1.8,1.647,1.647,0,0,0-1.923,1.036,5.764,5.764,0,0,0-.161,1.12"
            transform="translate(-92.589 -47.884)"
            fill="currentColor"
          />
          <path
            d="M113.771,170.587a2.9,2.9,0,1,1,2.886,2.914,2.923,2.923,0,0,1-2.886-2.914m4.367.016a1.468,1.468,0,1,0-1.458,1.467,1.477,1.477,0,0,0,1.458-1.467"
            transform="translate(-93.615 -137.97)"
            fill="currentColor"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default WarnBorder;
